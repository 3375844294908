// action types
import ApiService from "../api.service";
import {SET_ERROR} from "./auth.module";

export const PAYMENT_LIST = "payment/list";
export const PAYMENT_LOCATION = "payment/location";
export const PAYMENT_REVENUE = "payment/revenue";
export const PAYMENT_CUSTOMER = "payment/customer";

// mutation types

// state types
const state = {
    payment: []
};

const getters = {

};

const actions = {
    [PAYMENT_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAYMENT_LIST}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [PAYMENT_LOCATION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAYMENT_LOCATION}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [PAYMENT_REVENUE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAYMENT_REVENUE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [PAYMENT_CUSTOMER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAYMENT_CUSTOMER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

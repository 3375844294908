<template>
  <div>
    <el-dialog
        title="Nhắc nhở xác nhận khách hàng tham gia ca test speaking"
        :visible.sync="dialogVisible"
        :width="isMobile()?'80%':'35%'"
        :close-on-click-modal="false"
        :show-close="false"
    >
      <p>Tiến hành xác nhận khách hàng tham gia test speaking trước thời gian bắt đầu 01 tiếng.</p>
      <p>Tới thời gian xác nhận, vui lòng truy cập website nội bộ, vào chi tiết ca test để tiến hành xác nhận.</p>
      <p style="color:  #FF0000;font-weight: bold;">Tới thời gian xác nhận, vui lòng truy cập website nội bộ, vào chi
        tiết ca test để tiến hành xác nhận.</p>
      <table class="table table-bordered">
        <thead>
        <tr>
          <th scope="col">Khách hàng</th>
          <th scope="col">Thời gian xác nhận</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(customer,index) in listCustomer" :key="index">
          <th scope="row">{{ customer.name }}</th>
          <td>{{ customer.confirmTime }}</td>
        </tr>
        </tbody>
      </table>
      <footer >
        <div>
          <el-checkbox @change="chooseNotDisplay()" v-model="checked1" label="Không hiển thị lại trong ngày"
                       size="large"/>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <button type="button" class="btn btn-primary" @click="closeModal()">Đã hiểu</button>
        </div>
        <div></div>
      </footer>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import {CHECK_LIST_WAITING_PROGRESSING, HIDE_MODAL} from "../../../../core/services/store/customer/testInput.module";
import {mapActions} from "vuex";

export default {
  name: "CheckListTestInputModal",
  data() {
    return {
      dialogVisible: false,
      listCustomer: [],
      checked1: false
    }
  },
  mounted() {
    this.checkDisplayModal();
    this.getListWaitingProgressing();
  },
  methods: {
    closeModal() {
      this.$store.dispatch(HIDE_MODAL)
      // this.dialogVisible = false;
    },
    chooseNotDisplay() {
      if (this.checked1) {
        let today = moment().format('YYYY-MM-DD');
        localStorage.setItem('isDisplayMentionModal', today);
        return
      }
      localStorage.setItem('isDisplayMentionModal', '');
    },
    checkDisplayModal() {
      let data = localStorage.getItem('isDisplayMentionModal');
      let today = moment().format('YYYY-MM-DD');
      if (today === data) {
        this.$store.dispatch(HIDE_MODAL)
      }
    },
    getListWaitingProgressing() {
      this.$store.dispatch(CHECK_LIST_WAITING_PROGRESSING).then((data) => {
        this.listCustomer = data.data.map((customer) => {
          let hourStart = moment(customer.date[0]).format('HH:mm');
          let hourEnd = moment(customer.date[1]).format('HH:mm');
          let day = moment(customer.date[1]).format('DD-MM-YYYY')
          let confirmTime = `${hourStart} - ${hourEnd} ngày ${day}`
          return {
            name: customer.name,
            confirmTime: confirmTime
          }
        })
      }).finally(() => {
        this.dialogVisible = this.listCustomer.length > 0
      })
    },
    isMobile() {
      return (window.innerWidth <= 768) || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
  }
}
</script>

<style scoped>

</style>
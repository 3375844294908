import {ROLE} from '@/role';
import {PERMISSION} from "@/permission";
export default {
    data() {
        return {
            role_auth: ROLE,
            permission: PERMISSION,
        }
    },
    methods: {
        pushParamsUrl(params){
            history.pushState(
                {},
                null,
                this.$route.path +
                '?' +
                Object.keys(params).map(key => {
                    return (encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
                })
                    .join('&')
            )
        },
        pullParamsFromUrl(formQuery){
            for (const formQueryKey in formQuery) {
                if (Array.isArray(formQuery[formQueryKey])){
                    formQuery[formQueryKey] = this.$route.query[formQueryKey] ? this.$route.query[formQueryKey].split(',').map(Number): [];
                }else if (Number(this.$route.query[formQueryKey]) > 0){
                    formQuery[formQueryKey] = Number(this.$route.query[formQueryKey]);
                }else {
                    formQuery[formQueryKey] = this.$route.query[formQueryKey] ? this.$route.query[formQueryKey] : ''
                }
            }
        },
        noticeMessage(type, title, message) {
            this.$notify({
                type: type,
                title: title,
                message: message
            });
        }
    },
};
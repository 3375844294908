import moment from 'moment-timezone';

export function convertDateToString(date) {
    return date;
}

export function formatDate(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
    }
}

export function formatMonthYear(value) {
    if (value) {
        return moment(String(value)).format('MM/YYYY')
    }
}

export function agoDate(value) {
    var date = moment(value);
    return moment(date).fromNow();
}

export function formatDateTime(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    }
}

export function formatVND(value) {
    if (value === '' || value == null) {
        value = 0;
    }
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'VND'}).format(value)
}

export function formatPercent(value) {
    value = (value * 100);
    value = value.toFixed(2);
    return value += '%';
}

export function formatVNDNoPrefix(value) {
    if (value === '' || value == null) {
        value = 0;
    }
    let number = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'VND'}).format(value);
    return number.replace("₫", "");
}

import ApiService from "@/core/services/api.service";
export const GET_BUSINESS_LEVEL = "get_business_level";


const state = {};
const getters = {};
const actions = {
    // Get provinces
    [GET_BUSINESS_LEVEL](context, payload) {
        return new Promise(resolve => {
            ApiService.query('business-level', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};

// action types
import ApiService from "../../api.service";
import {SET_ERROR} from "../auth.module";

export const GET_SCHOOL_ALL = "schools/all";


// mutation types

// state types
const state = {
    payment: []
};

const getters = {};

const actions = {
    /**
     * Get tuition payments
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [GET_SCHOOL_ALL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${GET_SCHOOL_ALL}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

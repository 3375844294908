// action types
import ApiService from "../../api.service";
import {SET_ERROR} from "../auth.module";

export const CUSTOMER_LIST = "customer/list";
export const CUSTOMER_CREATE = "customer/create";
export const CUSTOMER_FIND = "customer/find";
export const CUSTOMER_FIND_RE_REGISTER = "customer/check-re-register";
export const CUSTOMER_CHECK_RE_DISPUTE = "customer/check-dispute";
export const CUSTOMER_UPDATE = "customer/update";
export const CENTER_LIST = "customer/list-center";
export const BRANCH_LIST = "customer/list-branch";
export const LICH_TRUC_TU_VAN = "customer/lich-truc-tu-van";
export const DOI_TU_VAN_VIEN = "customer/doi-tu-van-vien";
export const DAT_LICH_TU_VAN = "customer/dat-lich-tu-van";
export const DAT_LICH_DOI = "customer/dat-lich-doi";
export const CHECK_BEFORE_CONSULT_SCHEDULE = "customer/check-before-consult-schedule";
export const GET_INFO_PAYMENT_SCHEDULE = "customer/get-info-payment-schedule";
export const PAYMENT_SCHEDULE = "customer/payment-schedule";
export const NHAP_KET_QUA_TU_VAN = "customer/nhap-ket-qua-tu-van";
export const CUSTOMER_DESTROY = "customer/destroy";
export const LIST_TUITION_PAYMENT_METHOD = 'customer/tuitionPayments';
export const LIST_INSTALLMENT_METHOD = 'customer/installmentMethods';
export const STORE_INSTALLMENT_INFORMATION = 'customer/installmentInformation'
export const UPDATE_INSTALLMENT_INFORMATION = 'customer/installmentInformation/update'
export const CUSTOMER_CONSULT_RESULT = "customer/consult-result";
export const CUSTOMER_CHECK_KH_PHAN_VAN = "customer/check-customer-phan-van";
export const CUSTOMER_UPDATE_LEVEL = "customer/update-level";
export const GET_CUSTOMER_BY_PHONE = "customer/get-customer-by-phone";

export const GET_TESTERT = "customer/get-testert";
export const GET_INPUT_SCHEDULE = "customer/input_schedule";
export const GET_DETAIL_CUSTOMER_FINANCE = "customer/detail/finance"
export const GET_DETAIL_CUSTOMER_STUDY = "customer/detail/study"
export const GET_DATA_TICKET_CUSTOMER = "customer/detail/ticket"
export const GET_DATA_HISTORY_CUSTOMER = "customer/history"
export const CUSTOMER_INFORMATION = "customer/user";
export const GET_TEST_INPUT_USER = "customer/test-input";
export const GET_USER_PARENT = "customer/user-parent";
export const GET_POLICY = "contract-online/get_policy_contract";
export const CUSTOMER_SCHEDULE_LIST = "customer/customers/show/schedule";


// mutation types

// state types
const state = {
    payment: []
};

const getters = {};

const actions = {
    [CUSTOMER_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${CUSTOMER_LIST}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CUSTOMER_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CUSTOMER_CREATE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    // resolve(response);
                    // context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CUSTOMER_FIND](context, id) {
        return new Promise(resolve => {
            ApiService.get(`/${CUSTOMER_FIND}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CUSTOMER_FIND_RE_REGISTER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${CUSTOMER_FIND_RE_REGISTER}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CUSTOMER_CHECK_RE_DISPUTE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${CUSTOMER_CHECK_RE_DISPUTE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CUSTOMER_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CUSTOMER_UPDATE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    // context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [BRANCH_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${BRANCH_LIST}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CENTER_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${CENTER_LIST}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [LICH_TRUC_TU_VAN](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${LICH_TRUC_TU_VAN}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [DOI_TU_VAN_VIEN](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${DOI_TU_VAN_VIEN}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [DAT_LICH_TU_VAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${DAT_LICH_TU_VAN}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [DAT_LICH_DOI](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${DAT_LICH_DOI}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CHECK_BEFORE_CONSULT_SCHEDULE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${CHECK_BEFORE_CONSULT_SCHEDULE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [GET_INFO_PAYMENT_SCHEDULE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${GET_INFO_PAYMENT_SCHEDULE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [PAYMENT_SCHEDULE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${PAYMENT_SCHEDULE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [NHAP_KET_QUA_TU_VAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${NHAP_KET_QUA_TU_VAN}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [CUSTOMER_DESTROY](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${CUSTOMER_DESTROY}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
      [NHAP_KET_QUA_TU_VAN](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${NHAP_KET_QUA_TU_VAN}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },

    /**
     * danh sách hình thức thanh toán học phí
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [LIST_TUITION_PAYMENT_METHOD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${LIST_TUITION_PAYMENT_METHOD}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },

    /**
     * danh sách tình trạng trả góp
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [LIST_INSTALLMENT_METHOD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${LIST_INSTALLMENT_METHOD}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },

    /**
     * tạo thông tin trả góp
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [UPDATE_INSTALLMENT_INFORMATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${STORE_INSTALLMENT_INFORMATION}/${payload.id}` + '?_method=PUT', payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },

    /**
     * cập nhật thông tin trả góp
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [STORE_INSTALLMENT_INFORMATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${STORE_INSTALLMENT_INFORMATION}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    /**
     * Nhập kết quả tư vấn
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CUSTOMER_CONSULT_RESULT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/${CUSTOMER_CONSULT_RESULT}/${payload.id}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    // context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    /**
     * Check nếu khách hàng phân vân(3E và của sale khác) thì không được taạo phiếu đăng ký
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CUSTOMER_CHECK_KH_PHAN_VAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CUSTOMER_CHECK_KH_PHAN_VAN}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [CUSTOMER_UPDATE_LEVEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CUSTOMER_UPDATE_LEVEL}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [GET_CUSTOMER_BY_PHONE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_CUSTOMER_BY_PHONE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [GET_DETAIL_CUSTOMER_FINANCE](context, id) {
        return new Promise(resolve => {
            ApiService.get(`/${GET_DETAIL_CUSTOMER_FINANCE}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },


    [GET_TESTERT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_TESTERT}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [GET_DATA_TICKET_CUSTOMER](context, id) {
        return new Promise(resolve => {
            ApiService.get(`/${GET_DATA_TICKET_CUSTOMER}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },

    [GET_INPUT_SCHEDULE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_INPUT_SCHEDULE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [GET_DATA_HISTORY_CUSTOMER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_DATA_HISTORY_CUSTOMER}/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response);
            });
        })
    },
    [GET_DETAIL_CUSTOMER_STUDY](context, id) {
        return new Promise(resolve => {
            ApiService.get(`/${GET_DETAIL_CUSTOMER_STUDY}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CUSTOMER_INFORMATION](context, id) {
      return new Promise((resolve) => {
        ApiService.get(`/${CUSTOMER_INFORMATION}`, id)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            resolve(response);
            context.commit(
              SET_ERROR,
              response && response.data ? response.data.message : ""
            );
          });
      });
    },
    [GET_TEST_INPUT_USER](context, payload) {
        return new Promise(resolve => {
          ApiService.query(`${GET_TEST_INPUT_USER}/${payload.id}`, {}).then(({data}) => {
            resolve(data);
          }).catch((response) => {
            console.log(response);
          });
        })
    },
    [GET_USER_PARENT](context, id) {
        return new Promise((resolve) => {
            ApiService.get(`/${GET_USER_PARENT}`, id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    resolve(response);
                    context.commit(
                        SET_ERROR,
                        response && response.data ? response.data.message : ""
                    );
                });
        });
    },
    [GET_POLICY](context,id) {
        return new Promise((resolve) => {
            ApiService.get(`/${GET_POLICY}`,id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    resolve(response);
                    context.commit(
                        SET_ERROR,
                        response && response.data ? response.data.message : ""
                    );
                });
        });
    },
    [CUSTOMER_SCHEDULE_LIST](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${CUSTOMER_SCHEDULE_LIST}/${id}`).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

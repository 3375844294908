import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_REPORT_FINANCE_GDVP = "finances/report/gdvp";
export const GET_REPORT_FINANCE_GDKV = "finances/report/gdkv";
export const GET_REPORT_FINANCE_GDV = "finances/report/gdvung";
export const GET_REPORT_GROWTH_GDKV = "finances/report/growth-gdkv";
export const GET_REPORT_GROWTH_SALES_GDKV = "finances/report/growth-sales-gdkv";


export const CATE_CONTENT_NAME_OFFICE_REPORTS = {
    tong_doanh_thu: {
        name: 'I. TỔNG DOANH THU',
        key: true,   
    },
    doanh_thu_doi_kinh_doanh: {
        name: 'Doanh thu đội kinh doanh',
        key: false,   
    },
    doanh_thu_nhan: {
        name: 'Doanh thu nhân',
        key: false,   
    },
    doanh_thu_digital_marketing: {
        name: 'Doanh thu digital marketing',
        key: false,   
    },
    tong_chi_phi: {
        name: 'II. TỔNG CHI PHÍ',
        key: true,   
    },
    chi_luong_kinh_doanh: {
        name: 'Chi lương kinh doanh',
        key: false,   
    },
    chi_san_pham: {
        name: 'Chi sản phẩm',
        key: false,   
    },
    chi_backoffice: {
        name: 'Chi backoffice',
        key: false,   
    },
    chi_phuc_loi: {
        name: 'Chi phúc lợi',
        key: false,   
    },
    chi_luong_hckv: {
        name: 'Chi lương HCKV',
        key: false,   
    },
    chi_van_phong: {
        name: 'Chi văn phòng',
        key: false,   
    },
    chi_khac: {
        name: 'Chi khác',
        key: false,   
    },
    loi_nhuan: {
        name: 'III. LỢI NHUẬN',
        key: false,   
    },
    tai_chinh_van_phong_thang_truoc: {
        name: 'Tài chính văn phòng tháng trước',
        key: false,   
    },
    phan_tram_co_phan: {
        name: 'Phần trăm cổ phần',
        key: false,   
    },
    tai_chinh_van_phong_thang_nay: {
        name: 'Tài chính văn phòng tháng này',
        key: false,   
    },
    gia_tri_tuong_ung_phan_tram_co_phan_tai_van_phong: {
        name: 'Giá trị tương ứng phần trăm cổ phần tại văn phòng',
        key: false,   
    },
    chi_co_tuc_thang: {
        name: 'Chi cổ tức tháng',
        key: false,   
    },
    gia_tri_co_tuc_tang_len_thang_nay: {
        name: 'Giá trị cổ tức tăng lên tháng này',
        key: false,   
    },
    von_gop_co_dong: {
        name: 'Vốn góp cổ đông',
        key: false,   
    },
    von_gop_thuc_te: {
        name: 'Vốn góp thực tế',
        key: false,   
    },
    von_gop_bo_sung: {
        name: 'Vốn góp bổ sung',
        key: false,   
    },
    loi_nhuan_chia_trong_thang: {
        name: '50% lợi nhuận chia trong tháng',
        key: false,
    },
    thu_nhap_kpis: {
        name: 'Thu nhập KPI',
        key: false,
    },
    chi_co_tuc_thang_van_phong: {
        name: 'Chi cổ tức tháng văn phòng',
        key: false,
    },
    gia_chi_co_tuc_van_phong_tang_len_thang_nay: {
        name: 'Giá trị cổ tức văn phòng tăng lên tháng này',
        key: false,
    },
    phan_tram_co_phan_rsm: {
        name: '2.1 Phần trăm cổ phần RSM',
        key: false,
    },
    phan_tram_co_phan_rsm_van_phong_dau_tien: {
        name: '2.2 Phần trăm cổ phần RSM văn phòng đầu tiên',
        key: false,
    },
}

const state = {};
const getters = {};
const actions = {
    // Get provinces
    [GET_REPORT_FINANCE_GDVP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_REPORT_FINANCE_GDVP, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
                console.log(response)
            });
        })
    },

    [GET_REPORT_FINANCE_GDKV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_REPORT_FINANCE_GDKV, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)

                console.log(response)
            });
        })
    },

    [GET_REPORT_FINANCE_GDV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_REPORT_FINANCE_GDV, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)

                console.log(response)
            });
        })
    },

    [GET_REPORT_GROWTH_GDKV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_REPORT_GROWTH_GDKV, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)

                console.log(response)
            });
        })
    },
    [GET_REPORT_GROWTH_SALES_GDKV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_REPORT_GROWTH_SALES_GDKV, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
                console.log(response)
            });
        })
    }
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};

export const LUONG_CUNG = 1;
export const LUONG_MEM = 2;
export const LUONG_CUNG_THEO_BAC = 3;
export const LUONG_CUNG_CO_DINH = 4;

export const mechanisms = [
    {
        id: 2,
        name: 'Lương mềm'
    },
    {
        id: 3,
        name: 'Lương cứng theo bậc'
    },
    {
        id: 4,
        name: 'Lương cứng cố định'
    },
    {
        id: 5,
        name: 'Lương cứng partime'
    }
];


export const mechanisms2 = [
    {
        id: 1,
        name: 'Lương cứng'
    },
    {
        id: 2,
        name: 'Lương mềm'
    },
];

export const mechanismFull = [
    {
        id: 1,
        name: 'Lương cứng'
    },
    {
        id: 2,
        name: 'Lương mềm'
    },
    {
        id: 3,
        name: 'Lương cứng theo bậc'
    },
    {
        id: 4,
        name: 'Lương cứng cố định'
    },
    {
        id: 5,
        name: 'Lương cứng partime'
    }
];





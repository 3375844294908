// action types
import ApiService from "../../api.service";
import {SET_ERROR} from "../auth.module";

export const CONTRACT_ONLINE_CREATE = "contract-online/store";
export const CONTRACT_ONLINE_UPDATE = "contract-online/update";
export const CONTRACT_FIND_BY_CUSTOMER = "contract-online/by-customer-id";
export const CONTRACT_ONLINE_GET_TUITION = "contract-online/get-all-tuition";
export const CONTRACT_ONLINE_SEND_LINK_SMS = "contract-online/send-link-confirm-sms";
export const CONTRACT_ONLINE_SEND_OTP_BY_PHONE = "contract-online/send-otp-by-phone";
export const CONTRACT_ONLINE_VERIFY_PHONE_PHONE = "contract-online/verify-phone";
export const CONTRACT_ONLINE_LIST_BY_TVV = "contract-online/contracts";
export const CONTRACT_ONLINE_GET_BY_ID = "contract-online/get-by-id";
export const CONTRACT_ONLINE_GET_SHORT_LINK = "contract-online/get-short-link";
export const GET_POLICY = "contract-online/get-policy";

// mutation types

// state types
const state = {
    payment: []
};

const getters = {};

const actions = {
    /**
     * Get tuition payments
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_GET_TUITION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${CONTRACT_ONLINE_GET_TUITION}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Get contracts
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_LIST_BY_TVV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${CONTRACT_ONLINE_LIST_BY_TVV}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Get contract by id
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_GET_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/${CONTRACT_ONLINE_GET_BY_ID}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Create contract online
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CONTRACT_ONLINE_CREATE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Update contract online
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CONTRACT_ONLINE_UPDATE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Send link sms
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_SEND_LINK_SMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CONTRACT_ONLINE_SEND_LINK_SMS}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Send otp
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_SEND_OTP_BY_PHONE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CONTRACT_ONLINE_SEND_OTP_BY_PHONE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Verify phone
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_VERIFY_PHONE_PHONE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CONTRACT_ONLINE_VERIFY_PHONE_PHONE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Get contract by customer id
     * @param context
     * @param id
     * @returns {Promise}
     */
    [CONTRACT_FIND_BY_CUSTOMER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/${CONTRACT_FIND_BY_CUSTOMER}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     * Get short link
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [CONTRACT_ONLINE_GET_SHORT_LINK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CONTRACT_ONLINE_GET_SHORT_LINK}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [GET_POLICY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_POLICY, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

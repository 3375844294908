import ApiService from "@/core/services/api.service";
//action types
export const GET_BRANCHES = "branches";
export const GET_BRANCH_BY_ID = "get_branch_by_id";
export const GET_AREA_BY_ID = "branch/get-area-by-id";
export const GET_BRANCHES_ALL = "branche_all";
export const GET_AREAS = "branch/areas";
export const GET_AREAS_BY_REGION = "branch/get-area-by-region";
export const GET_BRANCHS_BY_REGION = "branch/get-branch-by-region";
export const GET_BRANCHES_BY_AREAS = "get_branches_by_areas";

//mutations type
export const SET_BRANCHES = "setBranches";

const state = {
    errors: null,
    branches: [],
};

const getters = {
    getAllBranches(state) {
        return state.branches;
    },
};

const actions = {
    [GET_BRANCHES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query("/branch", credentials)
                .then(({data}) => {
                    context.commit(SET_BRANCHES, data.data.data);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_BRANCH_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('branches', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_AREA_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get(GET_AREA_BY_ID, id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    /**
     * danh sách khu vực
     * @param context
     * @param credentials
     * @returns {Promise}
     */
    [GET_AREAS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_AREAS}`, credentials)
                .then(({data}) => {
                    context.commit(SET_BRANCHES, data.data.data);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    /**
     * danh sách chi nhánh theo khu vực
     * @param context
     * @param credentials
     * @returns {Promise}
     */
    [GET_BRANCHES_BY_AREAS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('branch/list', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_BRANCHES_ALL](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query("/branches", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_BRANCHS_BY_REGION](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_BRANCHS_BY_REGION}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_AREAS_BY_REGION](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_AREAS_BY_REGION}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
};

const mutations = {
    [SET_BRANCHES](state, data) {
        state.branches = data;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};

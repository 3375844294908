import {TRUNG_TAM, TINH} from "./area";
import {LUONG_MEM, LUONG_CUNG_THEO_BAC, LUONG_CUNG_CO_DINH} from "./mechanism";
import {JUNIOR, ROOKIE, EXECUTIVE, PRO, MASTER,} from './businessLevel'

export const SALE_LEADER = 1;
export const SALE_EXPERT = 2;
export const SALE_MEMBER = 3;
export const SALE_INTERN = 4;
export const GDVP = 5;
export const GDKV = 6;
export const TEACHER = 7;
export const SUPPORT = 8;
export const STUDENT = 9;
export const CENTER = 10;
export const BRANCH = 11;
export const QLHV = 12;
export const CENTER_ASSIGN = 13;
export const STAFF = 14;
export const BOD = 15;
export const TEAMLEADER = 16;
export const HANH_CHINH_KHU_VUC = 18;
export const UNG_VIEN = 29;

export const DOANH_SO_VI_TRI_MOI_SI = 24000000;
export const DOANH_SO_VI_TRI_MOI_S = 99000000;
export const DOANH_SO_VI_TRI_MOI_SE = 300000000;

export const DOANH_SO_CA_NHAN = 24000000;

export const DOANH_SO_DOI_SE = 66000000;
export const DOANH_SO_DOI_SL = 200000000;

export const accountTypeOption = [
    {name: QLHV, value: "Quản lý học viên"},
    {name: SALE_INTERN, value: "Sale Intern"},
    {name: SALE_MEMBER, value: "Sale"},
    {name: SALE_EXPERT, value: "Sale Expert"},
    {name: SALE_LEADER, value: "Sale Leader"},
    {name: GDVP, value: "Giám đốc văn phòng"},
];

export const kiemNghiemOption = [
    {id: 'TPOnline', name: 'TP Online'},
    {id: 'SLX', name: 'SLX'},
    {id: 'TPTT', name: 'TP Truyền thông'},
    {id: 'TPNS', name: 'TP Nhân sự'},
];


export const kiemNghiemNew = [
    {id: 'TPOnline', name: 'TP Online'},
    {id: 'TVV', name: 'Tư vấn viên'},
    {id: 'Trainer', name: 'Trainer'},
];

export const businessLevels = [
    {
        id: 1,
        name: 'Junior'
    },
    {
        id: 2,
        name: 'Rookie'
    },
    {
        id: 3,
        name: 'Excutive'
    },
    {
        id: 4,
        name: 'Pro'
    },
    {
        id: 5,
        name: 'Master'
    }
]
export const levels = {
    1: 'Cấp 1',
    2: 'Cấp 2',
    3: 'Cấp 3',
    4: 'Cấp 4',
    5: 'Cấp 5'
}

export const TAI_KHOAN_CAP_TREN = [
    {id: 'sale-member', value: "Sale"},
    {id: 'sale-leader', value: "Sale Leader"},
];

export const ACCOUNT_TYPE_TEXT_TO_INT = {
    'sale-member': SALE_MEMBER,
    'sale-leader': SALE_LEADER
}

export const TAI_KHOAN_CAP_TREN_ONLY_SALE = [
    {id: 'sale-leader', value: "Sale Leader"},
];

export const TPOL = ['TPOL', 'TPOnline'];
export const TPNS = ['TPNS'];
export const TVV = ['TVV'];
export const TRAINER = ['Trainer'];
export const POSITION = ['TVV', 'TPOnline', 'Trainer'];

export const GDVPorGDKV = [GDVP, GDKV];

export const WATCH_AS_TP = [
    {
        id: 0,
        value: 'TPhòng/Giám đốc',
        label: 'TP/GĐ'
    },
    {
        id: 1,
        value: 'Đội kinh doanh',
        label: 'Đội'
    },
    {
        id: 2,
        value: 'Cá nhân',
        label: 'Cá nhân'
    }
];

export const WATCH_AS_SALE = [
    {
        id: 1,
        value: 'Đội kinh doanh',
        label: 'Đội'
    },
    {
        id: 2,
        value: 'Cá nhân',
        label: 'Cá nhân'
    }
];

//PERSONAL
let PERSONAL_TRUNG_TAM = {}
PERSONAL_TRUNG_TAM[JUNIOR] = 25000000;
PERSONAL_TRUNG_TAM[ROOKIE] = 25000000;
PERSONAL_TRUNG_TAM[EXECUTIVE] = 30000000;
PERSONAL_TRUNG_TAM[PRO] = 35000000;
PERSONAL_TRUNG_TAM[MASTER] = 40000000;

let PERSONAL_TINH_LUONG_MEM = {}
PERSONAL_TINH_LUONG_MEM[JUNIOR] = 25000000;
PERSONAL_TINH_LUONG_MEM[ROOKIE] = 25000000;
PERSONAL_TINH_LUONG_MEM[EXECUTIVE] = 30000000;
PERSONAL_TINH_LUONG_MEM[PRO] = 35000000;
PERSONAL_TINH_LUONG_MEM[MASTER] = 40000000;

let PERSONAL_TINH_LUONG_CUNG_THEO_BAC = {}
PERSONAL_TINH_LUONG_CUNG_THEO_BAC[JUNIOR] = 50000000;
PERSONAL_TINH_LUONG_CUNG_THEO_BAC[ROOKIE] = 60000000;
PERSONAL_TINH_LUONG_CUNG_THEO_BAC[EXECUTIVE] = 70000000;
PERSONAL_TINH_LUONG_CUNG_THEO_BAC[PRO] = 80000000;
PERSONAL_TINH_LUONG_CUNG_THEO_BAC[MASTER] = 90000000;

let PERSONAL_TINH_LUONG_CUNG_CO_DINH = {}
PERSONAL_TINH_LUONG_CUNG_CO_DINH[JUNIOR] = 55000000;
PERSONAL_TINH_LUONG_CUNG_CO_DINH[ROOKIE] = 55000000;
PERSONAL_TINH_LUONG_CUNG_CO_DINH[EXECUTIVE] = 55000000;
PERSONAL_TINH_LUONG_CUNG_CO_DINH[PRO] = 55000000;
PERSONAL_TINH_LUONG_CUNG_CO_DINH[MASTER] = 55000000;

export const TARGET_PERSONAL = {}
TARGET_PERSONAL[TRUNG_TAM] = PERSONAL_TRUNG_TAM;
TARGET_PERSONAL[TINH] = {};
TARGET_PERSONAL[TINH][LUONG_MEM] = PERSONAL_TINH_LUONG_MEM;
TARGET_PERSONAL[TINH][LUONG_CUNG_THEO_BAC] = PERSONAL_TINH_LUONG_CUNG_THEO_BAC;
TARGET_PERSONAL[TINH][LUONG_CUNG_CO_DINH] = PERSONAL_TINH_LUONG_CUNG_CO_DINH;


// TEAM
let TARGET_TEAM_TRUNG_TAM = {};
TARGET_TEAM_TRUNG_TAM[JUNIOR] = 100000000;
TARGET_TEAM_TRUNG_TAM[ROOKIE] = 150000000;
TARGET_TEAM_TRUNG_TAM[EXECUTIVE] = 200000000;
TARGET_TEAM_TRUNG_TAM[PRO] = 250000000;
TARGET_TEAM_TRUNG_TAM[MASTER] = 300000000;

let TARGET_TEAM_TINH = {};
TARGET_TEAM_TINH[JUNIOR] = 250000000;
TARGET_TEAM_TINH[ROOKIE] = 300000000;
TARGET_TEAM_TINH[EXECUTIVE] = 350000000;
TARGET_TEAM_TINH[PRO] = 400000000;
TARGET_TEAM_TINH[MASTER] = 450000000;

export const TARGET_TEAM = {}
TARGET_TEAM[TRUNG_TAM] = TARGET_TEAM_TRUNG_TAM;
TARGET_TEAM[TINH] = TARGET_TEAM_TINH;

export const types = [
    {
        id: 1,
        name: 'Trung tâm',
    },
    {
        id: 2,
        name: 'Tỉnh',
    }
]

export const typeFormatText = {
    1: 'Trung tâm',
    2: 'Tỉnh'
}
export const TRUNG_TAM = 1;
export const TINH = 2;







// action types
import ApiService from "../api.service";
import {SET_ERROR} from "./auth.module";

export const REPORT_HUMANRE_SOURCE = "report/human-resource";
export const REPORT_KINH_DOANH_DOI = "payment/bao-cao-doi";
export const REPORT_KINH_DOANH_DOI_V2 = "payment/bao-cao-doi-v2";
export const REPORT_KINH_DOANH_DOI_CHI_TIET = "payment/bao-cao-doi-chi-tiet";
export const REPORT_KINH_DOANH_DOI_CHI_TIET_V2 = "payment/bao-cao-doi-chi-tiet-v2";
export const REPORT_KINH_DOANH_VAN_PHONG = "payment/kinh-doanh-van-phong";

// mutation types

// state types
const state = {
};

const getters = {

};

const actions = {
    [REPORT_HUMANRE_SOURCE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REPORT_HUMANRE_SOURCE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [REPORT_KINH_DOANH_DOI](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REPORT_KINH_DOANH_DOI}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [REPORT_KINH_DOANH_DOI_V2](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REPORT_KINH_DOANH_DOI_V2}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [REPORT_KINH_DOANH_DOI_CHI_TIET](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${REPORT_KINH_DOANH_DOI_CHI_TIET}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [REPORT_KINH_DOANH_DOI_CHI_TIET_V2](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${REPORT_KINH_DOANH_DOI_CHI_TIET_V2}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [REPORT_KINH_DOANH_VAN_PHONG](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REPORT_KINH_DOANH_VAN_PHONG}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

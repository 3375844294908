import ApiService from "@/core/services/api.service";
import {GET_USER_BY_ID} from "@/core/services/store/user/users.module";

const _ = require('lodash');

export const GET_COURSE_LEVELS = "course-level-list";
export const GET_LIST_COURSE = "get-list-course";
export const GET_LIST_COURSE_BY_AREA = "get-list-course-by-area";
export const GET_CANDIDATE_LOGIN = "get-candidate-login";
export const SHOW_QUANTURM_LEAP = "get-quan-leap";
export const REDIRECT_QUANTURM_LEAP = "redirect-quanturm-leap"; 
export const GET_USER_CANDIDATE = "get-user-candidate";

const state = {
    errors: null,
    courseLevels: [],
    courseLevelPaginateData: [],
};

const getters = {};

const actions = {
    [GET_COURSE_LEVELS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("courseLevels", credentials).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },
    [GET_LIST_COURSE](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query(GET_LIST_COURSE, credentials).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },
    [GET_LIST_COURSE_BY_AREA](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_COURSE_BY_AREA).then(({data}) => {
                // context.commit(SET_USER_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_CANDIDATE_LOGIN](context) {
        return new Promise((resolve) => {
            ApiService.query(GET_CANDIDATE_LOGIN).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },
    [REDIRECT_QUANTURM_LEAP](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(REDIRECT_QUANTURM_LEAP, payload).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },

    [SHOW_QUANTURM_LEAP](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(SHOW_QUANTURM_LEAP, payload).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },

    [GET_USER_CANDIDATE](context, phone) {
        return new Promise((resolve) => {
            ApiService.query(GET_USER_CANDIDATE, phone).then(({data}) => {
                resolve(data);
            }).catch((res) => {
                console.log(res);
            });
        });
    },
}

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};

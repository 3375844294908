export const PERMISSION = {
    /* Header */
    DANG_KI_LAM :'dang-ki-lam',
    CHAM_CONG :'cham-cong',
    DANH_SACH :'danh-sach',
    HANH_CHINH :'hanh-chinh',
    TU_VAN: 'tu-van',
    BAO_CAO : 'bao-cao',
    THONG_TIN :'thong-tin',
    /* child url */
    XEM_CHAM_CONG :'xem-cham-cong',
    CHAM_CONG_NHAN_SU :'cham-cong-nhan-su',
    CHAM_CONG_VAN_TAY :'cham-cong-van-tay',
    DONG_TIEN_THEO_DOI_KD :'dong-tien-theo-doi-kd',
    DONG_TIEN_TAI_DIEM :'dong-tien-tai-diem',
    PHAN_CAP_NHAN_SU :'phan-cap-nhan-su',
    KHACH_HANG :'khach-hang',
    NHAN_SU_HOAT_DONG :'nhan-su-hoat-dong',
    NHAN_SU_CHO_XET_DUYET :'nhan-su-cho-xet-duyet',
    PHIEU_LUONG :'phieu-luong',
    THU_NHAP_VAN_PHONG :'thu-nhap-van-phong',
    KHACH_HANG_TU_VAN :'khach-hang-tu-van',
    DOANH_SO_TU_VAN :'doanh-so-tu-van',
    DANG_KI_LICH_TRUC :'dang-ky-lich-truc',
    LICH_TU_VAN :'lich-tu-van',
    NHAN_SU_TRONG_THANG :'nhan-su-trong-thang',
    KINH_DOANH_DOI :'kinh-doanh-doi',
    KINH_DOANH_VAN_PHONG :'kinh-doanh-van-phong',
    SUA_CHI_NHANH :'sua-chi-nhanh',
    HE_THONG_THONG_TIN :'he-thong-thong-tin',
    THONG_BAO_TU_EDUTALK :'thong-bao-tu-edutalk',
    CHINH_SACH_NHAN_SU :'chinh-sach-kinh-doanh',
    CAM_NANG_VAN_HANH :'cam-nang-van-hanh',
    NOTIFICATION_ALL :'tat-ca-thong-bao',
    CANDIDATE: 'ung-vien',
    DUYET_DON: 'de-xuat',
    DUYET_DE_XUAT: 'duyet-de-xuat',

    BAO_CAO_VAN_PHONG: 'bao-cao-van-phong',
    BAO_CAO_KHU_VUC: 'bao-cao-khu-vuc',
    BAO_CAO_VUNG: 'bao-cao-vung',
    TOC_DO_TANG_TRUONG_KHU_VUC: 'toc-do-tang-truong-khu-vuc',
    TOC_DO_TANG_TRUONG_DOANH_SO_KHU_VUC: 'toc-do-tang-truong-doanh-so-khu-vuc',
    BAO_CAO_HOC_TAP: 'bao-cao-hoc-tap',
}

import Vue from "vue";
import lang from 'element-ui/lib/locale/lang/vi'
import locale from 'element-ui/lib/locale'
import { Select, Option, Dialog,  } from 'element-ui'
import ElementUI from 'element-ui'
import VueQRCodeComponent from 'vue-qrcode-component'

import 'element-ui/lib/theme-chalk/index.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import EdutalkDatePicker from "@/views/content/date-picker/src/picker/date-picker";
import EdutalkDateCalendar from "@/views/content/calendar/src/main";

// import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

Vue.component('edutalk-date-picker', EdutalkDatePicker);
Vue.component('edutalk-date-calendar', EdutalkDateCalendar);

Vue.component('edutalk-select', Select);
Vue.component('edutalk-option', Option);
Vue.component('edutalk-modal', Dialog);
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(ElementUI, { locale });
locale.use(lang);

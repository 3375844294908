<template>
  <div>
    <router-view></router-view>
  </div>

</template>
<style lang="scss">
  // 3rd party plugins css
  @import "assets/css/vendors.bundle.css";
  @import "assets/css/app.bundle.css";
  @import "assets/css/edutalk-font.css";
  @import "assets/css/fa-brands.css";
  @import "assets/css/fa-regular.css";
  @import "assets/css/fa-solid.css";
  @import "assets/css/selectize";
  @import "assets/css/fullcalendar.bundle.css";
  @import "assets/css/ck-editoir.scss";

  // Main demo style scss
  @import "assets/sass/style.vue";
  .line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .line-clamp-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
  .line-clamp-5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }


  // Check documentation for RTL css
  // Update HTML with RTL attribute at public/index.html
</style>
<script>
  import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
  import CheckListTestInputModal from "./views/layout/header/components/CheckListTestInputModal.vue";

  export default {
    name: "EduTalk",
    components: {CheckListTestInputModal},
    mounted() {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static json (@/core/config/layout.config.json)
       */
      this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
      window.addEventListener('storage', (e) => {
        // When local storage changes, dump the list to
        if (this.$route.name !== 'login' && e.key === 'user'){
          window.location.reload();
        }
      });
    }
  };
</script>


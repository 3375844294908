import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset_password";
export const FACEBOOK_LOGIN = "facebook_login";
export const GOOGLE_LOGIN = "google_login";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
const state = {
    errors: null,
    user: JSON.parse(localStorage.getItem(`user`)) || {},
    isAuthenticated: !!JwtService.getToken(),
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    isPilot(state){
        return [2,14].includes(+state.user.area_id)
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("/login", credentials).then(({data}) => {
                context.commit(SET_AUTH, data.user);
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                reject(response)
            });
        });
    },
    [FACEBOOK_LOGIN](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("/login/facebook", credentials).then(({data}) => {
                context.commit(SET_AUTH, data.user);
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },
    [GOOGLE_LOGIN](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("/login/google", credentials).then(({data}) => {
                context.commit(SET_AUTH, data.user);
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },
    [LOGOUT](context) {
        return new Promise((resolve, reject) => {
            ApiService.post('logout')
                .then(({data}) => {
                    context.commit(PURGE_AUTH);
                    localStorage.removeItem('user');
                    resolve(data)
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.errors : '');
                    reject(response)
                });
        })

    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("register", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [FORGOT_PASSWORD](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("forgot-password", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [RESET_PASSWORD](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("password-reset", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {
            // ApiService.setHeader();
            // ApiService.post('verify')
            //   .then(({ data }) => {
            //     context.commit(SET_AUTH, data);
            //   })
            //   .catch(({ response }) => {
            //     context.commit(SET_ERROR, response && response.data ? response.data.errors : '');
            //   });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [UPDATE_PASSWORD](context, payload) {
        return new Promise(resolve => {
            ApiService.post("/change-password", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    if (response?.data?.data?.message_validate_form) {
                        context.commit(SET_ERROR, response.data.data.message_validate_form);
                    } else {
                        context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    }
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        localStorage.setItem('user', JSON.stringify(user));
        state.errors = {};
        JwtService.saveToken('Edutalk');
    },
    [SET_PASSWORD](state, password) {
        state.user.password = password;
    },
    [PURGE_AUTH](state) {
        JwtService.saveToken('Edutalk');
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        state.isWaitUsers = 0;
        localStorage.removeItem('isWaitUsers');
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};

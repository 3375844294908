// action types
import ApiService from "../../api.service";
import {SET_ERROR} from "../auth.module";

export const LIST_COURSE_BY_CENTER = "contract/list-course-by-center";
export const LIST_CLASSROOM_BY_COURSE = "contract/list-classroom-by-course";
export const CHECK_UNIQUE_EMAIL = "contract/check-unique-email";
export const GET_INFO_USER = "contract/get-info-user";
export const SEND_CODE_VERIFY = "contract/send-code-verify";
export const VERIFY_PHONE = "contract/verify-phone";
export const GET_COURSE_CONTRACT = "contract/get-course-contract";
export const CREATE_CONTRACT = "contract/create";
export const FIND_CONTRACT = "contract/find";
export const UPDATE_CONTRACT = "contract/update";
export const DELETE_CONTRACT = "contract/delete";
export const LIST_COMBO = "contract/list-combo";
export const LIST_VOUCHER = "contract/list-voucher";
export const LIST_GIFT = "contract/list-gift";
export const FIND_PROMOTION = "contract/find-promotion";
export const LIST_CONSULTANT_CONTRACT = "contract/sale-consultant";
export const SHOW_LAST_CONSULTANT_CONTRACT = "contract/sale-consultant/find-last";

// mutation types

// state types
const state = {
    payment: []
};

const getters = {

};

const actions = {
    [LIST_COURSE_BY_CENTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${LIST_COURSE_BY_CENTER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [LIST_CLASSROOM_BY_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${LIST_CLASSROOM_BY_COURSE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [CHECK_UNIQUE_EMAIL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CHECK_UNIQUE_EMAIL}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [GET_INFO_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${GET_INFO_USER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [SEND_CODE_VERIFY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${SEND_CODE_VERIFY}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [VERIFY_PHONE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${VERIFY_PHONE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [GET_COURSE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${GET_COURSE_CONTRACT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [CREATE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CREATE_CONTRACT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [CREATE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CREATE_CONTRACT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [FIND_CONTRACT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/${FIND_CONTRACT}`, id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [DELETE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${DELETE_CONTRACT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [UPDATE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${UPDATE_CONTRACT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [LIST_COMBO](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${LIST_COMBO}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [LIST_VOUCHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${LIST_VOUCHER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [LIST_GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${LIST_GIFT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [FIND_PROMOTION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/${FIND_PROMOTION}`, id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    /**
     * danh sách hợp đồng tư vấn
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [LIST_CONSULTANT_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${LIST_CONSULTANT_CONTRACT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },

    /**
     * hợp đồng tư vấn vừa cập nhật
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [SHOW_LAST_CONSULTANT_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${SHOW_LAST_CONSULTANT_CONTRACT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

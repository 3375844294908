import ApiService from "@/core/services/api.service";
import { FIND_CONTRACT } from "../contract/contract.module";
import { DELETE_ROLE, SET_ROLES_ERROR, UPDATE_LIST_ROLE } from "@/core/services/store/user/role.module";
const _ = require('lodash');
//action types
export const CREATE_STAFF = "create_staff";
export const UPDATE_STAFF = "update_staff";
export const GET_USER_BY_ID = "get_user_by_id";
export const SET_USERS_INDEX = "set_user_index";
export const SEARCH_BY_MA_CAP_TREN = "search_by_ma_cap_tren";
export const GET_USERS = "list-user";
export const CREATE_USER = "createuser";
export const UPDATE_USER = "updateuser";
export const DELETE_USER = "delete_user";
export const UPDATE_USER_INPUT_VALUE = "updateuserinputvalue";
export const GET_USER_LOG_HISTORIES = "get_user_log_histories";
export const GET_USER_LOG_TIME_WORK = "get_user_log_time_work";
export const GET_USER_LOG_ACTION_DATA = "get_user_log_action_data";
export const DISMISS_USER = "dismiss_user";
export const ACTIVE_USER = "active_user";
export const SHOW_TYPE_BIRTH_DAY = "user/show-type-bd";
export const USER_GET_QR_CODE = 'qrcode';
export const CREATE_CANDIDATE = 'candidate';
export const CREATE_CANDIDATE_INTERNAL = 'api-app-internal';
export const UPDATE_CANDIDATE = 'CandidateController';
export const GET_CANDIDATE = "get-candidate";
export const UPDATE_STATUS_CANDIDATE = "update-action-candidate";
export const GET_CANDIDATE_BY_USER_ID = "get-candidate-by-user";
export const EXPORT_EXCEL = "export-excel";
export const IMPORT_EXCEL = "import-excel";
export const GET_DATA_IMPORT = "get-data-import";
export const DELETE_CANDIDATE = "delete-candidate";
export const EXPORT_EXCEL_CANDIDATE = "export-excel-candidate";
export const GET_USER_LOGIN = "get-user-login";
export const GET_CANDIDATE_ATTENDANCE = "get-candidate-attendance";
export const UPDATE_FAIL = "update-fail";
export const CANDIDATE_TYPES = [{ "label": "Sinh viên/Người đi làm", "description": "Học đầy đủ 4 buổi", "value": 0 },
{ "label": "Học sinh", "description": "Chỉ cần học buổi Đào tạo hội nhập", "value": 1 }];
export const CHECK_PARENT = "check-phone-parent";
export const VERIFILE_TELEGRAM_USER = "user/verifile-telegram-user";
export const CHECK_USER_VERIFILE = "user/check-verifile-user";

//mutations types
export const SET_USERS = "setUsers";
export const ADD_USERS = "addUsers";
export const SET_USERS_ERROR = "errorUsers";
export const SET_USER_BY_ID = "getUserById";
export const SET_INPUT_VALUE = "setuserinputvalue";
export const SET_USER_PAGINATE = "setuserpaginatevalue";
export const SET_USER_LOG_PAGINATE = "set_user_log_paginate_value";
export const SET_USER_LOG_HISTORIES = "set_user_log_histories";
export const SET_USER_LOG_DATA_CHART = "set_user_log_data_chart";
export const SET_USER_LOG_ACTION_DATA = "set_user_log_action_data";
export const UPDATE_LIST_USER = "set_users_when_active_user";
export const UPDATE_LIST_USER_WHEN_DELETE = "set_users_when_delete_user";
export const UPDATE_QRCODE_USER = "set_qrcode_user";
export const USER_GENERATE_USER_TOKEN_QUANTUMLEAP = "user_generate_user_token_quantumleap";

export const CONFIRM_WAITING = 0;
export const CONFIRM_SENT = 1;
export const CONFIRM_CONFIRMED = 2;
export const CONFIRM_REJECTED = 3;

export const UPDATE_PROPOSES_BY_UUID = 'user/update-proposes-user-by-uuid';
export const CHECK_SHOW = 'user/check-show-uuid';

export const GET_LIST_USER_REQUEST = "propose-management/get-propose-by-department";
export const GET_All_LIST_BOD = "propose-management/get-all-list-bod";
export const DELETE_USER_REQUEST   = "propose-management/propose";
export const CREATE_APPLICATION_REQUEST   = "propose-management";
export const SHOW_DETAIL_APPLICATION   = "propose-management/show-detail";
export const DECIDED_APPLITICATION   = "propose-management/decided-propose";
export const GET_TVV_BY_COURSE  = "get-tvv-by-course";

export const GET_USER_BY_PROGRAM_OPTIONAL  = "program-optional/candidate-sale-level-up";



const state = {
    errors: null,
    users: [],
    user: '',
    userPaginatedData: [],
    userLogPaginatedData: '',
    logHistories: '',
    logActionHistories: '',
    timeChartData: '',
};

const getters = {
    getAllUsers(state) {
        return state.users;
    },
    getUserById(state) {
        return state.user;
    },
    getUserPaginate(state) {
        return state.userPaginatedData;
    },
    getUserLogHistories(state) {
        return state.logHistories;
    },
    getUserLogHistoriesPaginate(state) {
        return state.userLogPaginatedData;
    },
    getUserLogChartData(state) {
        return state.timeChartData;
    },
    getUserActionLogData(state) {
        return state.logActionHistories;
    },
};

const actions = {
    [GET_USERS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/list-user", credentials)
                .then(({ data }) => {
                    context.commit(SET_USERS, data.data.data);
                    const userPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_PAGINATE, userPagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

    [GET_USER_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('users', id).then(({ data }) => {
                // context.commit(SET_USER_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_USER_LOG_ACTION_DATA](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("/user/log-action-history/" + payload.id, payload.params)
                .then(({ data }) => {
                    context.commit(SET_USER_LOG_ACTION_DATA, data.data.data);
                    const userLogActionPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_LOG_PAGINATE, userLogActionPagination)
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [GET_USER_LOG_HISTORIES](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("/user/log-history/" + payload.id, payload.params)
                .then(({ data }) => {
                    context.commit(SET_USER_LOG_HISTORIES, data.data.data);
                    const userLogPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_LOG_PAGINATE, userLogPagination)
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [GET_USER_LOG_TIME_WORK](context, id) {
        return new Promise((resolve) => {
            ApiService.get("/user/log-history-by-day", id)
                .then(({ data }) => {
                    context.commit(SET_USER_LOG_DATA_CHART, data.data);
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [CHECK_SHOW](context, payload) {
        return new Promise((resolve) => {
            ApiService.query(CHECK_SHOW, payload)
                .then(({ data }) => {
                    context.commit(SET_USER_LOG_DATA_CHART, data.data);
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [CREATE_STAFF](context, payload) {
        return new Promise(resolve => {
            ApiService.post('create-staff', payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [USER_GENERATE_USER_TOKEN_QUANTUMLEAP](context, payload) {
        return new Promise(resolve => {
            ApiService.post('user/generate-user-token-quantumleap', payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_STAFF](context, payload) {
        return new Promise(resolve => {
            ApiService.post('update-staff/' + payload.id, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [SEARCH_BY_MA_CAP_TREN](context, payload) {
        return new Promise(resolve => {
            ApiService.query('users', payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DISMISS_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.post('user/dismiss/' + payload.id, payload.params).then(({ data }) => {
                context.commit(UPDATE_LIST_USER, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ACTIVE_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.post('user/active/' + payload.id, payload.params).then(({ data }) => {
                context.commit(UPDATE_LIST_USER, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_USER](context, id) {
        return new Promise(resolve => {
            ApiService.delete('user', id).then(({ data }) => {
                context.commit(UPDATE_LIST_USER_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     * api loại nhân sự ngày sinh nhật
     * @param context
     * @param id
     * @returns {Promise}
     */
    [SHOW_TYPE_BIRTH_DAY](context, id) {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            if (localStorage.event) {
                let event = JSON.parse(localStorage.event);
                if (event.version !== process.env.VUE_APP_VERSION || event.version === undefined || dateString !== event.date || event.date === undefined) {
                    ApiService.query(`${SHOW_TYPE_BIRTH_DAY}`).then(({ data }) => {
                        resolve(data.data);
                        let dataVersion = data.data;
                        dataVersion.version = process.env.VUE_APP_VERSION
                        dataVersion.date = dateString
                        localStorage.event = JSON.stringify(dataVersion)
                    }).catch(({ response }) => {
                        reject(response)
                    });
                } else {
                    return resolve(event);
                }
            } else {
                ApiService.query(`${SHOW_TYPE_BIRTH_DAY}`).then(({ data }) => {
                    resolve(data.data);
                    let dataVersion = data.data;
                    dataVersion.version = process.env.VUE_APP_VERSION;
                    dataVersion.date = dateString;
                    localStorage.event = JSON.stringify(dataVersion)
                }).catch(({ response }) => {
                    reject(response)
                });
            }
        })
    },
    [USER_GET_QR_CODE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${USER_GET_QR_CODE}`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(UPDATE_QRCODE_USER);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [CREATE_CANDIDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CREATE_CANDIDATE, payload.data).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    [GET_CANDIDATE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_CANDIDATE, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [UPDATE_STATUS_CANDIDATE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(UPDATE_STATUS_CANDIDATE, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_CANDIDATE_BY_USER_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.query('candidate/' + payload.id).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [UPDATE_CANDIDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put('candidate/' + payload.data.id, payload.data).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },


    [EXPORT_EXCEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_EXCEL, payload).then(({ data }) => {
                let filename = "File-mau-import-ung-vien.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },

    [EXPORT_EXCEL_CANDIDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_EXCEL_CANDIDATE, payload).then(({ data }) => {
                let filename = payload.is_debt ? "danh-sach-ung-vien.xlsx" : "danh-sach-ung-vien.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },

    [IMPORT_EXCEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_EXCEL, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                // console.log(response)
                reject(response);
            });
        })
    },

    [GET_DATA_IMPORT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(GET_DATA_IMPORT, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                // console.log(response)
                reject(response);
            });
        })
    },

    [DELETE_CANDIDATE](context, listId) {
        return new Promise((resolve) => {
            ApiService.post(DELETE_CANDIDATE, listId)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                });
        });
    },
    [GET_USER_LOGIN](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_USER_LOGIN, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_CANDIDATE_ATTENDANCE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_CANDIDATE_ATTENDANCE, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_FAIL](context, payload) {
        return new Promise(resolve => {
            ApiService.post(UPDATE_FAIL + '/' + payload.id).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [VERIFILE_TELEGRAM_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.post(VERIFILE_TELEGRAM_USER, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CHECK_USER_VERIFILE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(CHECK_USER_VERIFILE, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CHECK_PARENT](context, payload) {
        return new Promise(resolve => {
            ApiService.query('user/check-phone-parent', payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_LIST_USER_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_USER_REQUEST}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_All_LIST_BOD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_All_LIST_BOD}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [CREATE_APPLICATION_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('propose-management/proposes', payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [DECIDED_APPLITICATION](context, payload) {
        return new Promise(resolve => {
            ApiService.post('propose-management/decided-propose', payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DELETE_USER_REQUEST](context, payload) {
        return new Promise(resolve => {
            ApiService.delete('propose-management/proposes', payload.id).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [SHOW_DETAIL_APPLICATION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${SHOW_DETAIL_APPLICATION}`, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [UPDATE_PROPOSES_BY_UUID](context, payload) {
        return new Promise(resolve => {
            ApiService.post(UPDATE_PROPOSES_BY_UUID, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_TVV_BY_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_TVV_BY_COURSE, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [UPDATE_PROPOSES_BY_UUID](context, payload) {
        return new Promise(resolve => {
            ApiService.query(UPDATE_PROPOSES_BY_UUID, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_USER_BY_PROGRAM_OPTIONAL](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_USER_BY_PROGRAM_OPTIONAL, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};


const mutations = {
    [SET_USERS](state, data) {
        state.users = data;
        state.errors = {};
    },
    [ADD_USERS](state, data) {
        state.users.push(data);
        state.errors = {};
    },
    [SET_USERS_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_BY_ID](state, data) {
        state.user = data;
    },
    [SET_INPUT_VALUE](state, e) {
        let user = state.user;
        user[e.target.name] = e.target.value;
        state.user = user
    },
    [SET_USER_PAGINATE](state, data) {
        state.userPaginatedData = data;
    }
    , [SET_USER_LOG_PAGINATE](state, data) {
        state.userLogPaginatedData = data;
    },
    [SET_USER_LOG_HISTORIES](state, data) {
        state.logHistories = data;
    },
    [SET_USER_LOG_DATA_CHART](state, data) {
        state.timeChartData = data;
    },
    [SET_USER_LOG_ACTION_DATA](state, data) {
        state.logActionHistories = data;
    },
    [UPDATE_LIST_USER](state, user) {
        let users = state.users;
        let oldUser = _.find(users, function (object) {
            return object.id === user.id;
        });
        oldUser.status = user.status;
    },
    [UPDATE_LIST_USER_WHEN_DELETE](state, id) {
        let users = state.users;
        let indexUser = _.findIndex(users, function (object) {
            return object.id === id;
        });
        users.splice(indexUser, 1);
    },
    [UPDATE_QRCODE_USER](state, user) {
        // let users = state.users;
        // let oldUser = _.find(users,function (object) {
        //     return object.id === user.id;
        // });
        // oldUser.qrcode = user.qrcode;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

export default {
    el: {
        colorpicker: {
            confirm: 'OK',
            clear: 'Xóa'
        },
        datepicker: {
            now: 'Hiện tại',
            today: 'Hôm nay',
            cancel: 'Hủy',
            clear: 'Xóa',
            confirm: 'OK',
            selectDate: 'Chọn ngày',
            selectTime: 'Chọn giờ',
            startDate: 'Ngày bắt đầu',
            startTime: 'Thời gian bắt đầu',
            endDate: 'Ngày kết thúc',
            endTime: 'Thời gian kết thúc',
            prevYear: 'Năm trước',
            nextYear: 'Năm tới',
            prevMonth: 'Tháng trước',
            thisMonth: 'Tháng này',
            nextMonth: 'Tháng tới',
            year: 'Năm',
            month1: 'Tháng 1',
            month2: 'Tháng 2',
            month3: 'Tháng 3',
            month4: 'Tháng 4',
            month5: 'Tháng 5',
            month6: 'Tháng 6',
            month7: 'Tháng 7',
            month8: 'Tháng 8',
            month9: 'Tháng 9',
            month10: 'Tháng 10',
            month11: 'Tháng 11',
            month12: 'Tháng 12',
            // week: 'week',
            weeks: {
                sun: 'Chủ nhật  ',
                mon: 'Thứ 2',
                tue: 'Thứ 3',
                wed: 'Thứ 4',
                thu: 'Thứ 5',
                fri: 'Thứ 6',
                sat: 'Thứ 7'
            },
            months: {
                jan: 'Th.1',
                feb: 'Th.2',
                mar: 'Th.3',
                apr: 'Th.4',
                may: 'Th.5',
                jun: 'Th.6',
                jul: 'Th.7',
                aug: 'Th.8',
                sep: 'Th.9',
                oct: 'Th.10',
                nov: 'Th.11',
                dec: 'Th.12'
            }
        },
        select: {
            loading: 'Đang tải',
            noMatch: 'Dữ liệu không phù hợp',
            noData: 'Không tìm thấy dữ liệu',
            placeholder: 'Chọn'
        },
        cascader: {
            noMatch: 'Dữ liệu không phù hợp',
            loading: 'Đang tải',
            placeholder: 'Chọn',
            noData: 'Không tìm thấy dữ liệu'
        },
        pagination: {
            goto: 'Nhảy tới',
            pagesize: '/trang',
            total: 'Tổng {total}',
            pageClassifier: ''
        },
        messagebox: {
            title: 'Thông báo',
            confirm: 'OK',
            cancel: 'Hủy',
            error: 'Dữ liệu không hợp lệ'
        },
        upload: {
            deleteTip: 'Nhấn xoá để xoá',
            delete: 'Xóa',
            preview: 'Xem trước',
            continue: 'Tiếp tục'
        },
        table: {
            emptyText: 'Không có dữ liệu',
            confirmFilter: 'Xác nhận',
            resetFilter: 'Làm mới',
            clearFilter: 'Xóa hết',
            sumText: 'Tổng'
        },
        tree: {
            emptyText: 'Không có dữ liệu'
        },
        transfer: {
            noMatch: 'Dữ liệu không phù hợp',
            noData: 'Không tìm thấy dữ liệu',
            titles: ['Danh sách 1', 'Danh sách 2'],
            filterPlaceholder: 'Nhập từ khóa',
            noCheckedFormat: '{total} mục',
            hasCheckedFormat: '{checked}/{total} đã chọn '
        },
        image: {
            error: 'LỖI'
        },
        pageHeader: {
            title: 'Quay lại'
        },
        popconfirm: {
            confirmButtonText: 'Ok',
            cancelButtonText: 'Huỷ'
        },
        empty: {
            description: 'Không có dữ liệu'
        }
    }
};

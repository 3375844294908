import ApiService from "@/core/services/api.service";
//action types
export const GET_CENTERS = "centers";
export const GET_CENTER_BY_ID = "get_center_by_id";

//mutations type
export const SET_BRANCHES = "setBranches";

const state = {
    errors: null,
};

const getters = {};

const actions = {
    [GET_CENTERS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query("centers", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_CENTER_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('centers', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters,
};

// action types
import ApiService from "../api.service";
import {SET_ERROR} from "./auth.module";

export const GET_NOTIFICATION = "notifications";
export const SET_NOTIFICATION_SCROLLER = "set_notification_scroller";
export const SET_NOTIFICATION = "set_notification";
export const SET_NOTIFICATION_NEW = "set_notification_new";
export const UPDATE_STATUS_READED = "notifications-update-readed";
export const UPDATE_STATUS_READED_ALL = "notifications-update-readed-all";
export const SET_PAGE = "set_page";
export const RESET_PAGE = "reset_page";
export const PUSH_DATA_LAST = "push_data_last";

// mutation types

// state types
const state = {
    notifications: [],
    notification_new: 0,
    page: 1,
    links: {
        1: '/phieu-luong',
        2: '/thong-tin/he-thong',
        3: '/thong-tin/thong-bao',
        4: '/thong-tin/chinh-sach-nhan-su',
        5: '/thong-tin/cam-nang-van-hanh',
        6: '/nhan-su-cho-duyet',
        7: '/work-calendar',
        8: '/lich-tuvan',
    }
};

const getters = {
    notifications(state) {
        return state.notifications;
    },
    notification_new(state) {
        return state.notification_new;
    },
    page(state) {
        return state.page;
    },
    links(state) {
        return state.links;
    },

};

const actions = {
    [GET_NOTIFICATION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_NOTIFICATION}`, payload)
                .then(({data}) => {
                    if (data.data.current_page !== 1) {
                        context.commit(SET_NOTIFICATION_SCROLLER, data.data);
                    } else {
                        context.commit(SET_NOTIFICATION, data.data);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },

    [UPDATE_STATUS_READED](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('notifications/update-readed', payload).then(({data}) => {
                context.commit(SET_NOTIFICATION_NEW);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_STATUS_READED_ALL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('notifications/update-readed-all', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [SET_PAGE](context, payload) {
        context.commit(SET_PAGE, payload);
    },
    [RESET_PAGE](context, payload) {
        context.commit(RESET_PAGE, payload);
    },

    [PUSH_DATA_LAST](context, payload) {
        context.commit(PUSH_DATA_LAST, payload.information);
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_NOTIFICATION](state, notifications) {
        if (notifications.data.length) {
            state.notifications = notifications;
            state.notification_new = notifications.number_notification_new;
        }
    },
    [SET_NOTIFICATION_SCROLLER](state, notifications) {
        if (notifications.data.length) {
            state.notifications.data.push(...notifications.data);
            state.notifications.page = notifications.current_page;
        }
    },
    [SET_NOTIFICATION_NEW](state) {
        state.notification_new--;
    },
    [SET_PAGE](state, payload) {
        state.page = payload;
    },
    [RESET_PAGE](state, payload) {
        state.notifications = [];
    },
    [PUSH_DATA_LAST](state, notification) {
        state.notifications.data.unshift(notification);
        state.notification_new++;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

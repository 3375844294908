import {SET_ERROR} from "./auth.module";
import ApiService from "../api.service";

export const TU_VAN_CENTER_LIST = "advise/center-list";
export const TU_VAN_COURSE_LIST = "advise/course-list";
export const TU_VAN_INDEX = "advise/list";
export const TU_VAN_DETAIL_COMBO = "advise/detail-combo";
export const TU_VAN_DETAIL_COURSE = "advise/detail-course";
export const TU_VAN_DETAIL_CLASSROOM = "advise/tvv-detail-classroom";
export const GET_DETAIL_CENTER = "advise/detail-center";
export const GET_LIST_STUDENT = "advise/show-students";

const state = {
    service: [],
};

const getters = {};

const actions = {
    [TU_VAN_CENTER_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TU_VAN_CENTER_LIST}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                resolve(response);
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },
    [TU_VAN_COURSE_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${TU_VAN_COURSE_LIST}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                resolve(response);
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },
    [TU_VAN_DETAIL_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('advise/tvv-detail-course', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                resolve(response);
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },

    [TU_VAN_DETAIL_CLASSROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${TU_VAN_DETAIL_CLASSROOM}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                resolve(response);
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },
    /**
     * Get list students
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [GET_LIST_STUDENT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_LIST_STUDENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                resolve(response);
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },

    [TU_VAN_INDEX](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TU_VAN_INDEX}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [TU_VAN_DETAIL_COMBO](context, payload) {
        return new Promise(resolve => {
            ApiService.query('advise/tvv-detail-course-combo', payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [GET_DETAIL_CENTER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_DETAIL_CENTER}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
}

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
// action types
import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "./auth.module";

export const LIST_CUSTOMER_TVV = "advise/list-customer-tvv";
export const DOANH_SO_TU_VAN = "advise/doanh-so-tu-van";


// mutation types

// state types
const state = {
    payment: []
};

const getters = {

};

const actions = {
    [LIST_CUSTOMER_TVV](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${LIST_CUSTOMER_TVV}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },

    [DOANH_SO_TU_VAN](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${DOANH_SO_TU_VAN}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

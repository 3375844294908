// action types
import ApiService from "../api.service";
import {SET_ERROR} from "./auth.module";
import {EXPORT_EXCEL} from "./profile.module";

export const BRANCH_LIST = "branch/list";
export const HIERARCHY   = "branch/hierarchy";
export const REGISTER_WORK   = "working/register";
export const REGISTER_STORE   = "working/store";
export const REGISTER_TOTAL   = "working/total-register";
export const SELECT_LEADER   = "working/select-leader";
export const GET_LEADER   = "user/by-account";
export const TIME_KEEP   = "working/time-keep";
export const TIME_KEEP_ATTENDANCE   = "working/time-keep-attendance";
export const TIME_KEEP_TOTAL   = "working/total";
export const GET_CHILD_USER   = "user/get-child-user";

export const INFORMATION_SYSTEM   = "information/system";
export const INFORMATION_MECHANISM = "information/chinh-sach";
export const INFORMATION_HANDBOOK = "information/cam-nang";
export const INFORMATION_INFORMATION = "information/thong-bao";
export const INFORMATION_INFORMATION_SLUG = "information/detail";



export const SCHEDULE_DUTY = "working/schedule-duty";
export const SCHEDULE_DUTY_UPDATE = 'working/schedule-duty/update';
export const SCHEDULE_DUTY_STORE = 'working/schedule-duty/store';
export const SCHEDULE_DUTY_DELETE = 'working/schedule-duty/delete';

export const SCHEDULE_CONSULTANT = 'working/schedule-consultant';
export const SCHEDULE_CONSULTANT_NO_RESULT = 'working/consultant-list/no-result';
export const SCHEDULE_CONSULTANT_KH_DEN = 'working/kh-den';
export const SWITCH_TVV = 'working/switch-tvv';
export const GET_SWITCH_TVV = 'get/working/switch-tvv';
export const POST_SWITCH_TVV = 'post/working/switch-tvv';

// Payslip
export const PAY_SLIP   = "payslip";
export const TIME_KEEPING = "okr/get-list";
export const PAY_SLIP_CENTER   = "payslip-center";
export const PAY_SLIP_CENTER_EXPORT   = "payslip-center-export";
export const GET_AREA = "get-area";

// contract
export const GET_CONTRACT_DELETE_VOUCHER   = "contract/get-contract-delete-voucher";

// Honor
const PATH_COMMENT = "honor-personnel/comment-honor"
export const GET_LIST_HONOR = "honor-personnel/program-honor";
export const UPDATE_ACTION_REACT = "honor-personnel/count-interact";
export const UPDATE_ACTION_SHARE = "honor-personnel/count-interact";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const GET_HONOR_BY_ID = "honor-personnel/honor-newsletter";
export const GET_LIST_COMMENT = "GET_LIST_COMMENT";

// mutation types

// state types
const state = {
    service: [],
};

const getters = {

};

const actions = {
    [BRANCH_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${BRANCH_LIST}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [HIERARCHY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${HIERARCHY}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },

    [GET_CHILD_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${GET_CHILD_USER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [REGISTER_WORK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REGISTER_WORK}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [REGISTER_STORE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${REGISTER_STORE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [SELECT_LEADER](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SELECT_LEADER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [REGISTER_TOTAL](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REGISTER_TOTAL}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [GET_LEADER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_LEADER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [TIME_KEEP](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TIME_KEEP}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [TIME_KEEP_ATTENDANCE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${TIME_KEEP_ATTENDANCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [TIME_KEEP_TOTAL](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TIME_KEEP_TOTAL}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_SYSTEM](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_SYSTEM}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_MECHANISM](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_MECHANISM}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_HANDBOOK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_HANDBOOK}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_INFORMATION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_INFORMATION}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_INFORMATION_SLUG](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_INFORMATION_SLUG}/${payload.slug}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [SCHEDULE_DUTY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${SCHEDULE_DUTY}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SCHEDULE_DUTY_UPDATE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SCHEDULE_DUTY_UPDATE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SCHEDULE_DUTY_STORE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SCHEDULE_DUTY_STORE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SCHEDULE_DUTY_DELETE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SCHEDULE_DUTY_DELETE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [PAY_SLIP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${PAY_SLIP}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    reject(response);
                });
        });
    },
    [TIME_KEEPING](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TIME_KEEPING}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [PAY_SLIP_CENTER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAY_SLIP_CENTER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },

    [PAY_SLIP_CENTER_EXPORT](context, payload) {
        let currentDate =  new Date();
        let month = payload.month ? payload.month : `${currentDate.getMonth()}-${currentDate.getFullYear()}`
        return new Promise((resolve, reject) => {
            ApiService.query(`${PAY_SLIP_CENTER_EXPORT}`, payload).then(({data}) => {
                let filename = `thu-nhap-van-phong-thang-${month}.xlsx`;
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(( {response} ) => {
                reject(response);
            });
        });
    },
    [SCHEDULE_CONSULTANT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${SCHEDULE_CONSULTANT}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SCHEDULE_CONSULTANT_KH_DEN](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SCHEDULE_CONSULTANT_KH_DEN}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },

    [SCHEDULE_CONSULTANT_NO_RESULT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/${SCHEDULE_CONSULTANT_NO_RESULT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                reject(response);
            });
        });
    },
    [GET_SWITCH_TVV](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${SWITCH_TVV}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [POST_SWITCH_TVV](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SWITCH_TVV}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [GET_AREA](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_AREA}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [GET_CONTRACT_DELETE_VOUCHER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_CONTRACT_DELETE_VOUCHER, id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [GET_LIST_HONOR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_HONOR, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [UPDATE_ACTION_REACT](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${UPDATE_ACTION_REACT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [UPDATE_ACTION_SHARE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${UPDATE_ACTION_SHARE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [CREATE_COMMENT](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${PATH_COMMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [GET_HONOR_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get(`${GET_HONOR_BY_ID}`, id).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_LIST_COMMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_COMMENT, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
